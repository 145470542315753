import React, { Component } from 'react';
import $ from 'jquery';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var bio2 = this.props.data.bio2;
      // var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      // var zip = this.props.data.address.zip;
      // var phone= this.props.data.phone;
      var email = this.props.data.email;
      // var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="Tim Baker Profile Pic" />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{bio} {bio2}</p>

            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
						   <span>Full Name: </span><br />
						   <span>  {name}</span><br />
						   <span>Address: </span><br />
						   <span>
						         {city} {state}
                   </span><br />
						   {/* <span>{phone}</span><br /> */}
						   <span>Email: <span 
                     style={{color: "#bbf4cd", cursor:"pointer"}}>                     
                     <a style={{color: "#bbf4cd", cursor:"pointer"}} href="mailto:soukaina.bouchane1@gmail.com">{email}</a>
                     </span></span>

					   </p>
               </div>
               <div className="columns download">
                  <p>
                     {/* <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a> */}
                  </p>
               </div>
            </div>
         </div>
      </div>

   </section>
    );
  }
}

$(document).ready(function(){
   $('.clipboard').on("click", function(){
       var value = $(this).data('ref');

       var $temp = $("<input>");
         $("body").append($temp);
         $temp.val(value).select();
         document.execCommand("copy");
         $temp.remove();

       var message = document.createElement('p');
       message.classList.add('effect');
       message.innerHTML = 'Email address copied !';
       this.parentNode.insertBefore(message, this);
       setTimeout(function () {
           $(message).remove();
       }, 2500);

   })
})

export default About;
